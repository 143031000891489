<script lang="tsx">
import { defineComponentBaseUiSelect } from '@core/app/components/base/ui/select/BaseUiSelect.vue'
import { IconChevron } from '#components'

export default defineComponentBaseUiSelect({
    slots: {
        dropdownIcon: () => <IconChevron class="text-blue" size={10} />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelect" as *;

</style>
