<script lang="tsx">
import { defineComponentBaseUiSelectInput } from '@core/app/components/base/ui/select/BaseUiSelectInput.vue'

export default defineComponentBaseUiSelectInput()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelectInput" as *;

@include select {
    background: $vut-c-white;
    border-radius: $vut-border-radius;
    border: hr-line();

    @include set-item-gap(1rem);
    @include set-padding(0.75rem, 1.25rem);
}

@include select--disabled {
    @include disabled-input;
}

@include select--focus {
    border: 1px solid $vut-c-secondary;
}

@include select--error {
    @include error-input;
}

@include placeholder {
    @include input-placeholder;
}

@include text {
    @include vut-text;
}

</style>
