<script lang="tsx">
import type { SetupContext, SlotsType } from 'vue'
import type { SizeProp } from '@core-types/components'
import type { FormFieldObject } from '@core-types/form'
import { BaseUiSelectInput } from '#components'
import { getBaseFormElementRuntimeProps } from '@core/app/utils/form'
import type { BaseFormElementProps } from '@core/app/utils/form'
import { type BaseUiSelectInputProps, getBaseUiSelectInputRuntimeProps } from './BaseUiSelectInput.vue'

export type SelectOption<T> = {
    label: string
    value: T
    disabled?: boolean
}

export type SelectOptionGroup<T> = {
    label: string
    options: SelectOption<T>[]
    disabled?: boolean
}

export type SelectOptions<T> = (SelectOption<T> | SelectOptionGroup<T> | string)[]

export type BaseUiSelectProps<T, Colors extends string, Variants extends string, Sizes extends string> = {
    /**
     * The autocomplete attribute for the input.
     */
    autocomplete?: FormAutocomplete
    /**
     * The options for the select input.
     */
    options: SelectOptions<T> | T[]
    /**
     * The getter of the value to be used as a label.
     */
    labelGetter?: Getter<T>
    /**
     * The getter of the value to be used as a value.
     */
    valueGetter?: Getter<T>

    /**
     * TODO: document
     */
    autoselect?: boolean | 'submit'
} & BaseUiSelectInputProps<Colors, Variants, Sizes> & BaseFormElementProps<SelectOption<T> | T>

/**
 * A function to return the runtime props definition of the BaseUiSelect component.
 * These props include the `BaseFormElementProps` and `BaseUiSelectInputProps` along with
 * the specific props of the `<BaseUiSelect />` component.
 * @param options The factory function options to override the default runtime props.
 */
export const getBaseUiSelectRuntimeProps = <TProps extends BaseUiSelectProps<any, any, any, any>, Colors extends string, Variants extends string, Sizes extends string>(options?: ComponentOverrideOptions<any, TProps>)
: RuntimeProps<Pick<TProps, keyof BaseUiSelectProps<any, any, any, any>>> =>
    ({
        ...defineRuntimeProps<BaseUiSelectProps<any, Colors, Variants, Sizes>>({
            placeholder: { type: String },
            // @ts-ignore
            autocomplete: { type: String },
            options: {
                type: Array,
                required: true,
            },
            // @ts-ignore
            labelGetter: { type: [Function, String] },
            // @ts-ignore
            valueGetter: { type: [Function, String] },
            autoselect: { type: [Boolean, () => 'submit'] },
        }, options),
        ...getBaseFormElementRuntimeProps([String, Number, Boolean, Object], options),
        ...getBaseUiSelectInputRuntimeProps<Colors, Variants, Sizes, BaseUiSelectProps<any, Colors, Variants, Sizes>>(options),
    })

export type BaseUiSelectSlots<T> = {
    default: {}
    leading: {
        data: T
    }
    trailing: {
        data: T
    }
    dropdownIcon: {}
}

type BaseUiSelectEmits<T> = {
    'update:modelValue': (value: SelectOptions<T> | T | null) => true,
    'update:form': (value: FormFieldObject<SelectOptions<T> | T | null>) => true,
    'selected': (value: SelectOptions<T> | T | null) => true,
}

type ComponentOptions = {}

export function defineComponentBaseUiSelect<
    Colors extends string,
    Variants extends string = '',
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiSelectProps<any, Colors, Variants, Sizes>, BaseUiSelectSlots<any>>) {
    return defineComponent(
        // @ts-expect-error - TODO: fix prop types
        <T extends string | number>(props: BaseUiSelectProps<T, Colors, Variants, Sizes>, ctx: SetupContext<BaseUiSelectEmits<T>, SlotsType<BaseUiSelectSlots<T>>>) => {

            const { injected } = useCoreUiFormProvide()
            const { injected: baseUiElementGroupInjected } = useBaseUiElementGroupProvide()

            const isDisabled = computed<boolean>(() => !!(props.disabled || props.loading))
            const isLeadingSlotRendered = computed<boolean>(() => ctx.slots.leading !== undefined || !!options?.slots?.leading)
            const isTrailingSlotRendered = computed<boolean>(() => !!(ctx.slots.trailing !== undefined || options?.slots?.trailing || ctx.slots.dropdownIcon !== undefined || options?.slots?.dropdownIcon))

            // TODO: REFACTOR
            // Save all options in a map for easier access by value
            const normalizedOptions = computed(() => {
                const map = new Map<unknown, SelectOption<T> | string | T>()
                for (const option of (props.options ?? [])) {
                    // if a getter is specified, it has priority
                    if (props.valueGetter) {
                        // @ts-expect-error
                        const value = getValueByGetter(option, props.valueGetter)
                        if (!value) {
                            errorLog('[BaseUiSelect]: The value getter returned a null value for the option:', option)
                            continue
                        }
                        map.set(value, option as T)
                        continue
                    }


                    if (isOptionGroup(option)) {
                        for (const opt of option.options) {
                            map.set(opt.value as string, opt)
                        }
                    } else {
                        map.set((isOptionObject(option) ? option.value : option) as string, option)
                    }
                }
                return map
            })

            function isOptionGroup(option: any): option is SelectOptionGroup<T> {
                return typeof option === 'object' && 'options' in option && 'label' in option
            }

            function isOptionObject(option: any): option is SelectOption<T> {
                return typeof option === 'object' && 'value' in option && 'label' in option
            }


            const inputValue = computed<SelectOptions<T> | T | null | undefined>({
                get() {
                    return props.modelValue as SelectOptions<T> | T | null | undefined
                },
                set(val) {
                    ctx.emit('update:modelValue', val ?? null)
                },
            })

            const formInputValue = computed<FormFieldObject<SelectOptions<T> | T | null> | undefined>({
                get() {
                    return props.form as FormFieldObject<SelectOptions<T> | T | null> | undefined
                },
                set(val) {
                    ctx.emit('update:form', val!)
                },
            })

            const internalValue = computed<SelectOptions<T> | T | null>({
                get() {
                    // make normal `v-model` have higher priority
                    if (inputValue.value !== undefined) return inputValue.value
                    // otherwise use the form input value binding
                    if (formInputValue.value === undefined) errorLog('[BaseUiSelect]: No v-model value provided', ...[getCurrentInstance()?.vnode.el].filter(Boolean))
                    return formInputValue.value?.__v ?? null
                },
                set(value) {
                    if (inputValue.value !== undefined) {
                        inputValue.value = value
                        // do not set form input value if normal `v-model` is used
                        // this is needed to prevent a bug, but I can't remember which one :(
                        return
                    }
                    if (formInputValue.value === undefined) return
                    formInputValue.value.__v = value
                },
            })

            const realInternalValue = computed(() => internalValue.value ? normalizedOptions.value.get(internalValue.value) : null)

            watch(internalValue, () => {
                ctx.emit('selected', internalValue.value)
            })

            const errorMessage = computed<string | null>(() => {
                if (!formInputValue.value || !injected.formErrors) return null
                return (injected.formErrors.value[formInputValue.value.__f] ?? null) as string | null
            })

            function handleInputChange() {
                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'change',
                    __f: formInputValue.value.__f,
                })
            }

            function handleInputBlur() {
                if (!injected.bus || !formInputValue.value) return

                injected.bus.emit({
                    type: 'blur',
                    __f: formInputValue.value.__f,
                })
            }

            function handleFormErrorReset() {
                if (!injected.resetFormError || !formInputValue.value) return
                injected.resetFormError(formInputValue.value.__f)
            }

            const isRequired = computed<boolean>(() => {
                return props.required ?? formInputValue.value?.__r ?? false
            })

            const isInvalid = computed<boolean>(() => {
                return props.ariaInvalid ?? !!errorMessage.value
            })

            // TODO: refactor
            // TODO: add prop not to auto-select the first option
            // Automatic selection of the first option if no value is selected
            if (internalValue.value === null && normalizedOptions.value.size > 0 && !props.placeholder && props.autoselect) {
                const initialValue = internalValue.value

                const firstOption = normalizedOptions.value.values().next().value
                if (props.valueGetter) {
                    // TODO: fix types
                    internalValue.value = getValueByGetter(firstOption as any, props.valueGetter as any)
                } else if (isOptionObject(firstOption)) {
                    internalValue.value = firstOption.value
                } else {
                    internalValue.value = firstOption as T
                }

                if (props.autoselect === 'submit') {
                    onMounted(async () => {
                        await injected.registeredBusPromise
                        if (initialValue !== internalValue.value && injected.bus && formInputValue.value) {
                            injected.bus.emit({
                                type: '_set-val',
                                __f: formInputValue.value.__f,
                            })
                        }
                    })
                }
            }

            function getValueString() {
                if (props.labelGetter) {
                    // TODO: fix types
                    return getValueByGetter(realInternalValue.value as any, props.labelGetter as any)
                }

                const currentValue = normalizedOptions.value.get(internalValue.value)
                return typeof currentValue === 'string'
                    ? currentValue
                    : isOptionObject(currentValue)
                        ? currentValue.label ?? null
                        : currentValue
            }

            const describedBy = computed<string | undefined>(() => {
                if (!props.descriptionId) return
                return Array.isArray(props.descriptionId) ? props.descriptionId.join(' ') : props.descriptionId
            })

            return () => (

                <BaseUiSelectInput
                    class={baseUiElementGroupInjected?.classes.value}
                    placeholder={props.placeholder}
                    placeholderAriaHidden
                    disabled={isDisabled.value}
                    invalid={isInvalid.value}
                    loading={props.loading ?? false}
                    inline={props.inline ?? false}
                    color={props.color as any}
                    variant={props.variant as any}
                    size={props.size as any}
                    leadingRendered={isLeadingSlotRendered.value}
                    trailingRendered={isTrailingSlotRendered.value}
                    leadingClass={props.leadingClass}
                    trailingClass={props.trailingClass}
                    paddingBehavior={props.paddingBehavior}
                    square={props.square ?? false}
                >
                    {{
                        default: () => (
                            <select
                                id={props.id}
                                v-model={internalValue.value}
                                class="sim-select__el"
                                disabled={isDisabled.value}
                                required={isRequired.value}
                                aria-describedby={describedBy.value}
                                aria-label={props.ariaLabel}
                                aria-invalid={isInvalid.value || undefined}
                                autocomplete={props.autocomplete}
                                onInput={handleFormErrorReset}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                            >
                                { /* if there is no item selected, show the placeholder */ internalValue.value === null && props.placeholder && (
                                    <option value="" selected disabled>
                                        {props.placeholder}
                                    </option>
                                )}

                                {(props.options ?? []).map((option, index) => {
                                    if (isOptionGroup(option)) {
                                        return (
                                            <optgroup
                                                key={`og${index}`}
                                                label={option.label}
                                                disabled={option.disabled}
                                            >
                                                {option.options.map((opt, i) => (
                                                    <option
                                                        key={`o${index}-${i}`}
                                                        value={opt.value}
                                                        disabled={opt.disabled}
                                                    >
                                                        {opt.label}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        )
                                    } else {
                                        return (
                                            <option
                                                key={`o${index}`}
                                                value={isOptionObject(option)
                                                    ? option.value
                                                    : props.valueGetter
                                                        ? getValueByGetter(option as any, props.valueGetter as any)
                                                        : option
                                                }
                                                disabled={isOptionObject(option) ? option.disabled : undefined}
                                            >
                                                {isOptionObject(option)
                                                    ? option.label
                                                    : props.labelGetter
                                                        ? getValueByGetter(option as any, props.labelGetter as any)
                                                        : option}
                                            </option>
                                        )
                                    }
                                })}
                            </select>
                        ),
                        leading: () => renderSlot(ctx.slots.leading, options?.slots?.leading, {
                            // @ts-ignore
                            data: realInternalValue.value,
                        }),
                        trailing: () => (
                            <>
                                {renderSlot(ctx.slots.trailing, options?.slots?.trailing, {
                                    // @ts-ignore
                                    data: realInternalValue.value,
                                })}
                                {renderSlot(ctx.slots.dropdownIcon, options?.slots?.dropdownIcon, {})}
                            </>
                        ),
                        // do not override the text slot if the placeholder should be shown (placeholder from props will be used)
                        text: internalValue.value === null && props.placeholder
                            ? undefined
                            : () => getValueString(),
                    }}
                </BaseUiSelectInput>
            )
        },
        {
            props: getBaseUiSelectRuntimeProps(options),
            slots: Object as SlotsType<BaseUiSelectSlots<any>>,
            emits: {
                'update:modelValue': (value: SelectOptions<any> | any | null) => true,
                'update:form': (value: FormFieldObject<SelectOptions<any> | any | null>) => true,
                'selected': (value: SelectOptions<any> | any | null) => true,
            } satisfies BaseUiSelectEmits<any>,
        }
    )
}

export default defineComponentBaseUiSelect()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelect.scss" as *;

</style>
